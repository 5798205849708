/**
 * Database Tables
 *
 * Here we export our Database collection names so that we can reuse them
 * across both client and server libraries
 *
 */

export const USERS_TABLE = `users`
export const ORGANIZATIONS_TABLE = `organizations`
export const MEMBERSHIPS_TABLE = `memberships`
export const SUBSCRIPTIONS_TABLE = `subscriptions`
export const ORGANIZATIONS_SUBSCRIPTIONS_TABLE = `organizations_subscriptions`
export const CSV_FILE = 'file_source'
export const CSV_DATA = 'file_data'
export const TRANSCRIPT_PROJECTS = 'transcript_projects'
export const TRANSCRIPT_QUESTION = 'transcript_question'
export const TRANSCRIPT_QUESTION_RESPONSE = 'transcript_question_response'
export const TRANSCRIPTION = 'transcription'
export const TRANSCRIPTION_PROJECTS_LINK = 'transcript_projects_link'
export const UTTERANCES = 'transcript_utterance'
export const SPEAKER_INFORMATION = 'speaker_information'
export const TOPIC_ANALYSIS_TABLE = 'topic_analysis'
export const RESEARCH_REPORTS = 'research_reports'